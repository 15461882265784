<template>
  <div class="country-states">
    <el-collapse accordion>
      <el-collapse-item title="Statest available" name="1">
        <div class="input-container">
          <div class="input-container__header">
            <label class="input-container__label" for="usa">USA STATES</label>
            <IconTooltipMessage :targetId="'usa-all-states'" />
          </div>
          <Chips
            name="usa"
            id="usa"
            v-model="formData.US"
            separator=","
            @add="convertInputToUpperCase()"
          />
        </div>
        <div class="input-container">
          <div class="input-container__header">
            <label class="input-container__label" for="canada">CANADA STATES</label>
            <IconTooltipMessage :targetId="'canada-all-states'" />
          </div>
          <Chips
            name="canada"
            id="canada"
            v-model="formData.CA"
            separator=","
            @add="convertInputToUpperCase()"
          />
        </div>
        <div class="input-container">
          <div class="input-container__header">
            <label class="input-container__label" for="mexico">MEXICO STATES</label>
            <IconTooltipMessage :targetId="'mexico-all-states'" />
          </div>
          <Chips
            name="mexico"
            id="mexico"
            v-model="formData.MX"
            separator=","
            @add="convertInputToUpperCase()"
          />
        </div>
        <div class="checkbox-container">
          <b-form-checkbox v-model="formData.us_us" class="checkbox-container__input"
            >USA - USA</b-form-checkbox
          >
          <b-form-checkbox v-model="formData.us_mx" class="checkbox-container__input"
            >USA - MX</b-form-checkbox
          >
          <b-form-checkbox v-model="formData.us_ca" class="checkbox-container__input"
            >USA - CA</b-form-checkbox
          >
          <b-form-checkbox v-model="formData.ca_ca" class="checkbox-container__input"
            >CA - CA</b-form-checkbox
          >
          <b-form-checkbox v-model="formData.mx_mx" class="checkbox-container__input"
            >MX - MX</b-form-checkbox
          >
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import IconTooltipMessage from './IconTooltipMessage.vue';

export default {
  name: "CountryStates",
  props: {
    value: Object,
  },
  components: {
    IconTooltipMessage,
  },
  data() {
    return {
      formData: {
        US: null,
        CA: null,
        MX: null,
        us_us: false,
        us_mx: false,
        us_ca: false,
        ca_ca: false,
        mx_mx: false,
      },
    };
  },
  watch: {
    formData: {
      deep: true,
      immediate: true,
      handler() {
        this.$emit("input", this.formData);
      },
    },
  },
  created() {
    this.formData = this.value;
  },
  methods: {
    convertInputToUpperCase() {
      this.formData.US = this.formData.US.map((option) => option.toUpperCase().trim());
      this.formData.CA = this.formData.CA.map((option) => option.toUpperCase().trim());
      this.formData.MX = this.formData.MX.map((option) => option.toUpperCase().trim());
    },
  },
};
</script>

<style lang="scss" scoped>
.country-states {
  width: 100%;
}

.input-container {
  display: flex;
  flex-direction: column;
  padding: 15px;
  margin: 15px 0px;
  border-radius: 13px;
  background-color: #efefef;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__label {
    text-align: start;
    font-size: 16px;
    font-weight: bold;
  }
}

.checkbox-container {
  width: 100%;
  text-align: start;
  padding-left: 10px;
  &__input {
    font-size: 15px;
  }
}

::v-deep {
  .el-collapse {
    width: 100%;
  }
  .el-collapse-item__header {
    font-size: 16px;
  }
  .p-chips {
    .p-chips-multiple-container {
      flex-wrap: wrap;
      gap: 3px;
      height: auto;
      width: 100%;
      padding: 5px;
      border-radius: 13px;
    }
    .p-chips-token {
      height: 25px;
      color: #d1d4d6 !important;
      background: #6c757d !important;
      border-radius: 5px !important;
    }
  }
}
</style>
