<template>
  <el-autocomplete
    v-model="selectedBillToName"
    :fetch-suggestions="searchBillTo"
    placeholder="Type to search"
    @select="selectBillTo"
    empty-text="No results found"
    :trigger-on-focus="false"
    :debounce="300"
    >
    </el-autocomplete>
</template>

<script>
export default {
  name: "BillToAutocomplete",
  props: {
    selectedBillTo: Object,
  },
  data() {
    return {
      selectedBillToName: null,
      skip: 0,
      limit: 10,
    };
  },
  watch: {
    selectedBillTo(billTo) {
      this.selectedBillToName = billTo.value || billTo.name;
    },
  },
  methods: {
    async searchBillTo(billToname, callback) {
      const params = {
        name: billToname,
        skip: this.skip,
        limit: this.limit,
      };
      const billToOptions = await this.$store.dispatch("accounts/getAccountBillingNames", params);
      if (!billToOptions.length) {
        return callback([]);
      }
      return callback(billToOptions.map((item) => ({
        value: item.name,
        id: item.id,
      })));
    },
    selectBillTo(selected) {
      this.$emit("BillToSelected", selected);
    },
  },
};
</script>

<style scoped>
:deep(.el-input__inner) {
  font-size: 1rem;
  height: 35px;
  line-height: 35px;
  font-size: 1rem;
  color: #495057;
  background: #ffffff;
  padding: 0.5rem 0.5rem;
  border: 1px solid #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
}
</style>
