import customerSpecifyPricing from "../../views/CSP/Index.vue";

const CustomerSpecifyPricingRoute = [
  {
    path: "/customer-specify-pricing",
    name: "customerSpecifyPricing",
    component: customerSpecifyPricing,
  },
];

export default CustomerSpecifyPricingRoute;
