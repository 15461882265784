import UserRepository from './UserRepository';
import ProfileRepository from './ProfileRepository';
import AccessorialRepository from './AccessorialRepository';
import CompanyRepository from './CompanyRepository';
import DepartmentRepository from './DepartmentRepository';
import CarriersRepository from './CarriersRepository';
import CannedResponsesRepository from './CannedResponsesRepository';
import RulesRepository from './RulesRepository';
import CSPRepository from './CSPRepository';
import AccountsRepository from './AccountsRepository';

const repositories = {
  users: UserRepository,
  profiles: ProfileRepository,
  accessorials: AccessorialRepository,
  companies: CompanyRepository,
  departments: DepartmentRepository,
  carriers: CarriersRepository,
  cannedResponses: CannedResponsesRepository,
  rulesAdmin: RulesRepository,
  customerSpecificPricing: CSPRepository,
  accounts: AccountsRepository,
};

export default {
  service: (name) => repositories[name],
};
