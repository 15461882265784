import Repository from "../../services/repositories/RepositoryFactory";
import { errorMessage, errorValidationMessage, tokenExpired } from '../../utils/alertMessages';

const customerSpecificPricing = Repository.service("customerSpecificPricing");

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async getAllACSP(_, payload) {
      try {
        const response = await customerSpecificPricing.getAllCSP(payload);
        return response.data;
      } catch (error) {
        if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          errorValidationMessage(error.response.data.detail[0].msg);
        } else if (error.response.data.detail) {
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },
    async getCSPById(_, id) {
      try {
        const response = await customerSpecificPricing.getCSPById(id);
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          errorValidationMessage(error.response.data.detail[0].msg);
        } else if (error.response.data.detail) {
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },
    async createCSP(_, payload) {
      try {
        const response = await customerSpecificPricing.createCSP(payload);
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          errorValidationMessage(error.response.data.detail[0].msg);
        } else if (error.response.data.detail) {
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },
    async updateCSP(_, payload) {
      try {
        const response = await customerSpecificPricing.updateCSP(payload);
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          errorValidationMessage(error.response.data.detail[0].msg);
        } else if (error.response.data.detail) {
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },
    async deleteCSP(_, id) {
      try {
        const response = await customerSpecificPricing.deleteCSP(id);
        return response;
      } catch (error) {
        if (error.response.status === 409) {
          errorValidationMessage("The record to be deleted is present in the business rules. You must first remove the dependency with the rule.");
        } else if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          errorValidationMessage(error.response.data.detail[0].msg);
        } else if (error.response.data.detail) {
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },
  },
};
