import { object, string, array } from 'yup';

const validationForm = object().shape({
  name: string().required("* field is required"),
  p44AccountGroup: string().required("* field is required"),
  parentAccountId: string()
    .when('customerIds', {
      is: (customerIds) => customerIds && customerIds.length > 0,
      then: (schema) => schema.notRequired(),
      otherwise: (schema) => schema.matches(/^001[A-Za-z0-9]{15}$/).required(),
    }),
  customerIds: array(),
  scacs: array(string()).min(1),
});

export default validationForm;
