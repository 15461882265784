import api from "./axios";

export default function setup() {
  api.interceptors.request.use(
    (config) => {
      const axiosConfig = config;
      const token = localStorage.getItem("user-access-token");
      if (token) {
        axiosConfig.headers.Authorization = `Bearer ${token}`;
        axiosConfig.headers.pragma = 'no-cache';
      }
      return axiosConfig;
    },
    (err) => Promise.reject(err),
  );
}
