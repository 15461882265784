<template>
  <b-modal
    id="carrier-modal"
    class="carrier-modal"
    :title="modalType + ' Carrier'"
    :ref="`${modalType.toLowerCase()}-carrier-modal`"
    ok-disabled
    hide-footer
    scrollable
  >
    <b-form class="carrier-modal__form">
      <div class="carrier-modal__row">
        <label class="carrier-modal__row--label" for="carrier-input">
          Carrier <b style="color: red">*</b></label
        >
        <div class="p-inputgroup carrier-modal__row--input">
          <span class="p-inputgroup-addon">
            <b-icon icon="truck"></b-icon>
          </span>
          <InputText
            :key="renderModal"
            name="carrier-input"
            v-model="carrierName"
            placeholder="Name"
            :disabled="loggedUser.role !== 'global_admin' || modalType === 'Edit'"
            :class="states.validName"
            @change="states.validName = changeFieldColors(carrierName)"
          />
          <Button
            @click="deleteCarrierName()"
            v-if="loggedUser.role === 'global_admin'"
            icon="pi pi-times"
            class="p-button-danger"
          />
        </div>
      </div>

      <div class="carrier-modal__row">
        <label class="carrier-modal__row--label" for="carrier-identification">
          Carrier Identification <b style="color: red">*</b></label
        >
        <div class="p-inputgroup carrier-modal__row--input">
          <span class="p-inputgroup-addon">
            <i class="pi pi-hashtag"></i>
          </span>
          <InputText
            id="carrier-identification"
            :key="renderModal"
            v-model="carrierIdentification"
            :disabled="loggedUser.role !== 'global_admin' || modalType === 'Edit'"
            placeholder="Carrier Identification"
            :class="states.validIdentification"
            @change="states.validIdentification = changeFieldColors(carrierIdentification)"
          />
        </div>
      </div>
      <div class="carrier-modal__row">
        <label class="carrier-modal__row--label" for="carrier-connection">
          Carrier Connection <b style="color: red">*</b></label
        >
        <div class="p-inputgroup carrier-modal__row--input">
          <span class="p-inputgroup-addon">
            <i class="pi pi-ellipsis-h"></i>
          </span>
          <Dropdown
            id="carrier-connection"
            v-model="selectedCarrierConnection"
            :options="carrierConnectionOptions"
            optionLabel="label"
            optionValue="value"
            placeholder="Select Carrier Connection"
            :disabled="loggedUser.role !== 'global_admin'"
          />
        </div>
      </div>
      <div class="carrier-modal__row">
        <label class="carrier-modal__row--label" for="carrier-connection">
          Bill To</label
        >
        <div class="p-inputgroup carrier-modal__row--input">
          <span class="p-inputgroup-addon">
            <i class="pi pi-money-bill"></i>
          </span>
          <Autocomplete
          :selectedBillTo = selectedBillTo
          @BillToSelected = updateBillToSelected
          />
        </div>
      </div>
      <div class="carrier-modal__row"
      v-if="modalType === 'Edit' && selectedCarrierConnection === 'stored_rate'">
        <DropdownUpdateRates
        ref="updateRatesComponent"
        :carrierName="carrierName"
        @filesValidation="handleFilesValidation"
      />
      </div>
      <div class="carrier-modal__column">
        <el-upload
          drag
          class="upload-demo"
          :limit="1"
          :auto-upload="false"
          accept=".png, .jpg, .jpeg"
          action="/upload"
          :on-change="dropAttachment"
          :file-list="fileList"
          :on-remove="handleRemove"
          :disabled="loggedUser.role !== 'global_admin'"
        >
          <div v-if="fileList && fileList.length === 0">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">Drop your file here or <em>click to upload</em></div>
          </div>
          <img
            v-else
            class="carrier-modal__image"
            :src="fileList && fileList.length > 0 ? fileList[0].url : fileList"
            :alt="fileList && fileList[0]?.name ? 'Logo' : ''"
          />
        </el-upload>
      </div>
      <div class="carrier-modal__row" v-if="selectedCarrierConnection === 'p44'">
        <CountryStates v-model="countryStatesForm" :value="countryStatesForm" />
      </div>
      <div class="carrier-modal__row carrier-modal__row--checkbox">
        <b-form-checkbox v-model="isInsuranceRecommend">Recommend insurance</b-form-checkbox>
      </div>
      <AssociateProfForm
        :entity="'carrier'"
        :entityId="infoCarrier?.carrier_id || infoCarrier?.id"
        :modalType="modalType"
        :selectedCarrierConnection="selectedCarrierConnection"
        @associateProfile="getAssociateProfile"
        @profileList="getProfileList"
      />
      <b-overlay class="overlay-button" :show="isLoading" rounded opacity="0.6" spinner-small>
        <b-button
          class="carrier-modal__button"
          size="sm"
          @click="modalType === 'Edit' ? verifyUpdate() : save()"
          :disabled="loggedUser.role === 'global_admin' ? !enableAction : false"
        >
          Save
        </b-button>
      </b-overlay>
    </b-form>
  </b-modal>
</template>

<script>
/* eslint-disable camelcase */
import { mapGetters } from "vuex";
import AssociateProfForm from "@/components/AssociateProfForm.vue";
import { promiseAssociate, constructMessage } from "@/mixins/associateProfile";
import CountryStates from "./CountryStates.vue";
import Autocomplete from "./Autocomplete.vue";
import DropdownUpdateRates from './DropdownUpdateRates.vue';
import {
  successMessage,
  warningMessage,
  errorDynamicMessage,
  deleteMessage,
} from "../../../utils/alertMessages";

export default {
  name: "CarriersModal",
  props: {
    modalType: String,
    infoCarrier: Object,
  },
  components: {
    AssociateProfForm,
    CountryStates,
    DropdownUpdateRates,
    Autocomplete,
  },
  data() {
    return {
      loggedUser: null,
      carrierName: "",
      carrierIdentification: "",
      isLoading: false,
      profileACtive: null,
      associateCarrier: false,
      states: {},
      enableAction: false,
      renderModal: 0,
      companyInfo: null,
      profileSelected: null,
      carrierId: null,
      selectedBillTo: null,
      selectedCarrierConnection: "p44",
      carrierConnectionOptions: [{ label: 'P44', value: "p44" }, { label: 'Stored_Rate', value: "stored_rate" }, { label: 'Easy_Post', value: "easy_post" }, { label: 'Special_Service', value: "special_service" }, { label: 'Dray_Master', value: "draymaster" }],
      fileList: [],
      image: null,
      loadedImage: false,
      isInsuranceRecommend: false,
      countryStatesForm: {},
      filesValidationError: false,
    };
  },
  computed: {
    ...mapGetters("company", ["showProfileCompanysDepartment"]),
  },
  watch: {
    infoCarrier() {
      this.updateInfoModal();
    },
    showProfileCompanysDepartment() {
      this.profileACtive = null;
      if (
        this.showProfileCompanysDepartment?.data
        && this.showProfileCompanysDepartment.data.length > 0
      ) {
        [this.companyInfo] = this.showProfileCompanysDepartment.data;
        [this.profileACtive] = this.showProfileCompanysDepartment.data;
      }
    },
  },
  created() {
    this.companyInfo = JSON.parse(localStorage.getItem("company-data"));
    this.loggedUser = JSON.parse(localStorage.getItem("user-info"));
    this.updateInfoModal();
  },
  methods: {
    show() {
      this.loadedImage = false;
      this.$refs[`${this.modalType.toLowerCase()}-carrier-modal`].show();
    },
    updateInfoModal() {
      this.associateCarrier = this.loggedUser.role !== "global_admin";
      if (this.modalType === "Edit" && this.infoCarrier) {
        const {
          us_us, us_mx, us_ca, ca_ca, mx_mx,
        } = this.infoCarrier;
        this.carrierName = this.infoCarrier.carrier_name || this.infoCarrier.name;
        this.carrierIdentification = this.infoCarrier.carrier_scac || this.infoCarrier.scac;
        this.isInsuranceRecommend = this.infoCarrier.insurance_alert;
        this.countryStatesForm = {
          US: this.infoCarrier.country_states.US,
          CA: this.infoCarrier.country_states.CA,
          MX: this.infoCarrier.country_states.MX,
          us_us,
          us_mx,
          us_ca,
          ca_ca,
          mx_mx,
        };
        this.fileList = this.infoCarrier?.logo ? [
          {
            name: this.carrierName,
            url: this.infoCarrier.logo,
          },
        ] : [];
        this.selectedCarrierConnection = this.infoCarrier.carrier_connection
          || this.infoCarrier.connection;
        if (this.infoCarrier.billing_account_id) {
          this.getBillToName();
        }
        this.colorFields();
      } else {
        this.cleanData();
      }
    },
    async getBillToName() {
      const response = (await this.$store.dispatch("accounts/getAccountNames", [this.infoCarrier.billing_account_id])).data[0];
      this.selectedBillTo = response;
    },
    updateBillToSelected(value) {
      this.selectedBillTo = value;
    },
    async verifyUpdate() {
      const warningResponse = await deleteMessage(
        'Are you sure you want to update the carrier?',
        "You won't be able to revert this!",
      );
      if (warningResponse.isConfirmed) {
        this.save();
      }
    },
    async save() {
      this.isLoading = true;
      this.colorFields();
      const checkInfo = this.validateInfo();

      if (checkInfo) {
        let msg;
        this.globalEdit = false;

        if (this.filesValidationError) {
          this.$message.error('Both Rates and Zones files are required.');
          this.isLoading = false;
          return;
        }

        if (this.modalType === "Edit") {
          msg = "edited";
          if (this.loggedUser.role === "global_admin") {
            if (this.fileList && this.fileList.length !== 0) {
              if (this.loadedImage) {
                await this.validateExtension();
              } else if (!this.loadedImage) {
                this.responseCarrier = await this.editCarrier();
                this.globalEdit = this.responseCarrier?.status === 204;
              }
            } else if (this.fileList.length === 0) {
              if (this.loadedImage) {
                warningMessage("Sorry", "The carrier must contain an image");
              } else {
                this.responseCarrier = await this.editCarrier();
                this.globalEdit = this.responseCarrier?.status === 204;
              }
            }
          } else {
            this.globalEdit = true;
          }
          this.carrierId = this.infoCarrier.carrier_id || this.infoCarrier.id;
        } else if (this.modalType === "Create New") {
          if (this.fileList && this.fileList.length !== 0) {
            await this.validateExtension();
            msg = "created";
            this.carrierId = this.responseCarrier?.data?.id;
          } else {
            warningMessage("Sorry", "The carrier must contain an image");
          }
        }
        if (this.modalType === 'Edit'
        && this.selectedCarrierConnection === 'stored_rate'
        && this.$refs.updateRatesComponent) {
          this.$refs.updateRatesComponent.$emit('triggerUpload');
        }
        if ((this.modalType === "Edit" && this.globalEdit)
              || (this.modalType === "Create New" && this.responseCarrier?.status === 201)) {
          if (this.associateCarrier) {
            await this.validateProfileList();
            this.isLoading = false;
          } else {
            successMessage(`The carrier has been ${msg}.`);
            this.cleanData();
            this.$emit("updateCarriers", true);
            this.isLoading = false;
            this.$refs[`${this.modalType.toLowerCase()}-carrier-modal`].hide();
          }
        }
      } else {
        warningMessage("Oops...", "Please complete the required fields.");
        this.isLoading = false;
      }
      this.isLoading = false;
    },
    handleFilesValidation(isInvalid) {
      this.filesValidationError = isInvalid;
    },
    async editCarrier() {
      const data = {};
      data.body = this.payloadConstructor();
      data.carrier_id = this.infoCarrier.carrier_id || this.infoCarrier.id;
      const response = await this.$store.dispatch("carriers/updateCarrier", data);
      this.isLoading = false;
      return response;
    },
    async createCarrier() {
      const body = this.payloadConstructor();
      const response = await this.$store.dispatch("carriers/createCarrier", body);
      this.isLoading = false;
      return response;
    },
    async carrierWithProfile(profile) {
      const data = {
        params: {
          profile_id: profile.profile_id,
          carrier_id: this.carrierId,
          company_id: profile.company_id || this.companyInfo.chosenCompany.id,
          department_id: profile.department_id || this.companyInfo.chosenDepartment.id,
        },
        body: {
          contract_type: profile.contractType ? profile.contractType : "density",
          status: profile.status ? profile.status : "active",
          dim_factor: profile.dim_factor ? profile.dim_factor : 0,
          default_notes: profile.default_notes ? profile.default_notes : null,
          is_tsa: profile.is_tsa ? profile.is_tsa : false,
        },
      };
      const response = await this.$store.dispatch("carriers/createCarrierProfile", data);
      this.isLoading = false;
      return response;
    },
    payloadConstructor() {
      const payload = {};
      payload.name = this.carrierName;
      payload.scac = this.carrierIdentification;
      payload.connection = this.selectedCarrierConnection;
      payload.billing_account_id = this.selectedBillTo?.id;
      payload.insurance_alert = this.isInsuranceRecommend;
      payload.country_states = {
        US: this.countryStatesForm.US || [],
        CA: this.countryStatesForm.CA || [],
        MX: this.countryStatesForm.MX || [],
      };
      const {
        us_us, us_mx, us_ca, ca_ca, mx_mx,
      } = this.countryStatesForm;
      payload.us_us = us_us || false;
      payload.us_mx = us_mx || false;
      payload.us_ca = us_ca || false;
      payload.ca_ca = ca_ca || false;
      payload.mx_mx = mx_mx || false;
      if (!this.fileList[0]) {
        [payload.logo] = this.fileList;
      }
      return payload;
    },
    validateInfo() {
      if (!this.carrierName || !this.carrierIdentification) {
        this.enableAction = false;
        return false;
      }
      if (this.associateCarrier) {
        if (
          this.profileSelected?.length < 1
          || !this.companyInfo.company_id
          || !this.companyInfo.department_id
        ) {
          this.enableAction = false;
          return false;
        }
      }
      this.enableAction = true;
      return true;
    },
    cleanColor() {
      this.states.validName = null;
      this.states.validIdentification = null;
    },
    colorFields() {
      if (this.loggedUser.role === "global_admin") {
        this.states.validName = this.changeFieldColors(this.carrierName);
        this.states.validIdentification = this.changeFieldColors(this.carrierIdentification);
      }
      this.validateInfo();
    },
    changeFieldColors(field) {
      this.validateInfo();
      if (this.loggedUser.role === "global_admin") {
        if (field) {
          this.renderModal += 1;
          return "carrier-modal__row--input-valid";
        }
        this.renderModal += 1;
        return "p-invalid";
      }
      return null;
    },
    cleanData() {
      this.carrierName = "";
      this.carrierIdentification = "";
      this.selectedCarrierConnection = 'p44';
      this.fileList = [];
      this.associateCarrier = this.loggedUser.role !== "global_admin";
      this.countryStatesForm = {};
      this.cleanColor();
      this.enableAction = false;
      this.selectedBillTo = null;
    },
    deleteCarrierName() {
      this.carrierName = null;
      this.states.validName = this.changeFieldColors(this.carrierName);
    },
    getAssociateProfile(value) {
      this.associateCarrier = value;
      this.validateInfo();
    },
    getProfileList(value) {
      this.profileSelected = value;
      this.validateInfo();
    },
    async validateProfileList() {
      const actionModal = this.modalType === "Edit" ? "updated" : "created";
      if (this.profileSelected.length > 0) {
        const response = await this.runCreateByProfiles();
        this.isLoading = false;
        if (response?.status === 201) {
          successMessage(`The carrier has been ${actionModal} and associated with profile(s)`);
          this.cleanData();
          this.$emit("updateCarriers", true);
          this.isLoading = false;
          this.$refs[`${this.modalType.toLowerCase()}-carrier-modal`].hide();
        } else {
          errorDynamicMessage(
            "Oops..",
            `The Carrier has been ${actionModal} but ${response?.msg}`,
          );
          this.$emit("updateCarriers", true);
        }
      } else {
        warningMessage("Oops..", "Please complete the profile selection.");
      }
    },
    async runCreateByProfiles() {
      const arrayResponses = [];
      this.profileSelected.forEach((profile) => {
        const response = this.carrierWithProfile(profile);
        arrayResponses.push(response);
      });
      const allResponse = await promiseAssociate(arrayResponses, this.profileSelected);
      const dataResponse = constructMessage(allResponse);
      return dataResponse;
    },
    async dropAttachment(event) {
      this.loadedImage = true;
      this.image = event?.raw;
      this.fileList = [
        {
          name: event?.raw.name,
          url: await this.blobToBase64(this.image),
        },
      ];
      this.validateInfo();
    },
    blobToBase64(blob) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    },
    async generateSignedUrl() {
      const params = {
        body: {
          carrier_name: null,
          extension: null,
        },
      };
      params.body.carrier_name = this.carrierName;
      params.body.extension = this.fileList[0]?.name.substr(this.fileList[0]?.name.lastIndexOf(".") + 1, this.fileList[0]?.name.length);
      params.image = this.image;
      await this.$store.dispatch("carriers/generateSignedUrl", params);
    },
    handleRemove() {
      this.fileList = [];
      this.loadedImage = true;
      this.validateInfo();
    },
    async validateExtension() {
      const extensions = ['png', 'jpg', 'jpeg'];
      const extension = this.fileList[0]?.name.substr(this.fileList[0]?.name.lastIndexOf(".") + 1, this.fileList[0]?.name.length).toLowerCase();
      if (extensions.includes(extension)) {
        this.responseCarrier = this.modalType === 'Edit' ? await this.editCarrier() : await this.createCarrier();
        await this.generateSignedUrl();
        this.globalEdit = this.responseCarrier?.status === 204;
      } else {
        warningMessage("Sorry", "The file must comply with the following extensions 'png, jpg, jpeg'");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.carrier-modal {
  &__form {
    text-align: center;
  }
  &__row {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2rem 0.5rem;
    &--label {
      color: $color-input-text;
      margin-left: 0.2rem;
      margin-right: 0.6rem;
      width: 100%;
      text-align: start;
    }
    &--input {
      width: 100%;
      @include input;
    }
    &--input-valid {
      border: 1px solid #00b900;
    }
    &--checkbox {
      align-items: start;
    }
    &__subtittle {
      color: $color-input-text;
      margin-top: 1.5rem;
    }
  }
  &__column {
    display: flex;
    flex-direction: column;
  }
  &__image {
    width: 250px;
    margin: 1rem;
  }
  &__button {
    @include submit-button;
    margin: 2rem auto 0.5rem;
    display: flex;
  }
}
:deep(.el-autocomplete){
  width: 100%;
}
</style>
