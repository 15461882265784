import Repository from "../../services/repositories/RepositoryFactory";
import { errorMessage, errorValidationMessage, tokenExpired } from '../../utils/alertMessages';

const AccountRepository = Repository.service("accounts");

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async getAccountsById(_, id) {
      try {
        const response = await AccountRepository.getAccountsById(id);
        return response;
      } catch (error) {
        if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          errorValidationMessage(error.response.data.detail[0].msg);
        } else if (error.response.data.detail) {
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },
    async getAccountBillingNames(_, payload) {
      try {
        const response = await AccountRepository.getBillingAccountsNames(payload);
        return response.data;
      } catch (error) {
        if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          errorValidationMessage(error.response.data.detail[0].msg);
        } else if (error.response.data.detail) {
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },
    async getAccountNames(_, payload) {
      try {
        const response = await AccountRepository.getAccountNames(payload);
        return response.data;
      } catch (error) {
        if (error.response.status === 401) {
          tokenExpired();
        } else if (error.response.status === 422) {
          errorValidationMessage(error.response.data.detail[0].msg);
        } else if (error.response.data.detail) {
          errorValidationMessage(error.response.data.detail);
        } else {
          errorMessage();
        }
        return error;
      }
    },
  },
};
