import api from '../clients/axios';

const resource = '/api/v1';

export default {
  async getAllCarriers(params) {
    const response = await api.get(`${resource}/carriers`, { params });
    return response;
  },
  async createCarrier(payload) {
    const response = await api.post(`${resource}/carriers`, payload);
    return response;
  },
  async getCarrierCountRates(params) {
    const response = await api.get(`${resource}/carriers/count`, { params });
    return response;
  },
  async getCarrierById(params) {
    const response = await api.get(`${resource}/carriers/${params.CarrierById}`, { params });
    return response;
  },
  async deleteCarrier(params) {
    const response = await api.delete(`${resource}/carriers/${params.carrier_id}?confirmation_message=${params.confirmation_message}`);
    return response;
  },
  async updateCarrier(params) {
    const response = await api.patch(`${resource}/carriers/${params.carrier_id}`, params.body);
    return response;
  },
  async getAllCarriersProfiles(params) {
    const response = await api.get(`${resource}/companies/${params.company_id}/departments/${params.department_id}/profiles/${params.profile_id}/carriers`, { params });
    return response;
  },

  async createCarrierProfile(payload) {
    const body = {
      status: payload.body.status,
      contract_type: payload.body.contract_type,
      dim_factor: payload.body.dim_factor,
      default_notes: payload.body.default_notes,
      is_tsa: payload.body.is_tsa,
    };
    const response = await api.post(`${resource}/companies/${payload.params.company_id}/departments/${payload.params.department_id}/profiles/${payload.params.profile_id}/carriers/${payload.params.carrier_id}`, body);
    return response;
  },
  async deleteCarrierProfile(params) {
    const response = await api.delete(`${resource}/companies/${params.company_id}/departments/${params.department_id}/profiles/${params.profile_id}/carriers/${params.carrier_id}?confirmation_message=${params.confirmation_message}`);
    return response;
  },
  async generateSignedUrl(params) {
    const response = await api.post(`${resource}/carriers/upload-url`, params);
    return response;
  },
  async uploadFileCarrier(payload) {
    const response = await api.post(`${resource}/carriers/upload-files`, payload.body, { params: { carrier_service: payload.carrier_service } });
    return response;
  },
  async saveCarrierLogo(params, body) {
    try {
      const response = await fetch(
        params.signed_url,
        {
          method: "PUT",
          body,
          headers: {
            "x-goog-meta-uploaded-by": params.headers["x-goog-meta-uploaded-by"],
            "Content-Type": "application/octet-stream",
          },
        },
      );
      return response;
    } catch (err) {
      return err;
    }
  },
  async getAllscacs() {
    const response = await api.get(`${resource}/carriers/p44/scacs`);
    return response;
  },
};
