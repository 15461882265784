import api from '../clients/axios';

const resource = '/api/v1/customer-specific-pricing';

export default {
  async getAllCSP(params) {
    const response = await api.get(`${resource}`, { params });
    return response;
  },
  async getCSPById(id) {
    const response = await api.get(`${resource}/${id}`);
    return response;
  },
  async createCSP(params) {
    const response = await api.post(`${resource}`, params);
    return response;
  },
  async updateCSP(params) {
    const response = await api.put(`${resource}/${params.id}`, params.body);
    return response;
  },
  async deleteCSP(id) {
    const response = await api.delete(`${resource}/${id}`);
    return response;
  },

};
