import api from "../clients/axios";

const resource = "/api/v1/accounts";

export default {
  async getAccountsById(id) {
    const response = await api.get(`${resource}/${id}`);
    return response.data;
  },
  async getBillingAccountsNames(payload) {
    const response = await api.get(`${resource}/billing-account?name=${payload.name}&skip=${payload.skip}&limit=${payload.limit}`);
    return response.data;
  },
  async getAccountNames(payload) {
    const response = await api.post(`${resource}`, payload);
    return response;
  },
};
