<template>
  <div>
    <!-- MODAL -->
    <b-modal
      hide-footer
      class="customer-specify-pricing-modal"
      ref="customer-specify-pricing"
      size="xl"
      :title="modalTitle"
    >
      <div class="customer-specify-pricing-modal__row">
        <div class="customer-specify-pricing-modal__column">
          <label class="customer-specify-pricing-modal__column__text" for="name">
            Name <b style="color: red">*</b></label>
          <InputText
            class="customer-specify-pricing-modal__column__input"
            :class="errorsForm.name"
            :key="renderInput"
            v-model.trim="cspForm.name"
            @change="validateField('name', {name:cspForm.name} )"
            id="name"
            type="text"
          />
        </div>
        <div class="customer-specify-pricing-modal__column">
          <label class="customer-specify-pricing-modal__column__text" for="p44AccountGroup">
            P44 Account Group <b style="color: red">*</b></label>
          <InputText
            class="customer-specify-pricing-modal__column__input"
            :class="errorsForm.p44AccountGroup"
            :key="renderInput"
            v-model.trim="cspForm.p44AccountGroup"
            @change="validateField('p44AccountGroup',
            {p44AccountGroup:cspForm.p44AccountGroup} )"
            type="text"
            id="p44AccountGroup"
          />
        </div>
        <div class="customer-specify-pricing-modal__column">
          <label class="customer-specify-pricing-modal__column__text" for="parentAccountId">
            Parent Account ID
            <b v-if="(cspForm.customerIds && cspForm.customerIds.length == 0)"
            style="color: red">*</b></label>
          <InputText
            class="customer-specify-pricing-modal__column__input"
            :class="errorsForm.parentAccountId"
            :key="renderInput"
            v-model.trim="cspForm.parentAccountId"
            @change="validateField('parentAccountId',
            {parentAccountId:cspForm.parentAccountId} )"
            id="parentAccountId"
            type="text"
          />
          <span v-if="accountIdValidator"
           class="customer-specify-pricing-modal__column__descripcion"
            > Invalid ID</span>
        </div>
      </div>
      <div class="customer-specify-pricing-modal__row">
        <div class="customer-specify-pricing-modal__column">
          <label class="customer-specify-pricing-modal__column__text" for="scacs">
            SCACS <b style="color: red">*</b></label>
            <MultiSelect v-model="cspForm.scacs"
            :options="scacsOptions"
            :class="errorsForm.scacs"
            :filter="true"
            @hide="validateField('scacs', {scacs: cspForm.scacs} )"
            />
        </div>
        <div class="customer-specify-pricing-modal__column">
          <label class="customer-specify-pricing-modal__column__text" for="customerIds">
            Customer's
            <b style="color: red"
            v-if="!cspForm.parentAccountId">*</b></label>
            <b-form-tags
                class="rules-modal__line__input"
                :class="errorsForm.customerIds"
                :key="renderInput"
                v-model="cspForm.customerIds"
                placeholder="Add customer ID"
                separator=","
                :tag-validator="customerIdValidator"
                @input="AddCustomerId()"
              >
              </b-form-tags>
        </div>
      </div>
      <b-overlay class="overlay-button" :show="isLoading" rounded opacity="0.6" spinner-small>
        <b-button
          v-if="modalTitle !== 'Edit CSP'"
          @click="handleRequest('create')"
          class="customer-specify-pricing-modal__button"
        >
          Save
        </b-button>
        <b-button v-else
        @click="handleRequest('edit')"
        class="customer-specify-pricing-modal__button">
          Save
        </b-button>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import { successMessage, warningMessage } from "@/utils/alertMessages";
import validationCSPForm from "@/YupSchemas/CSPForm";

export default {
  name: "CSPModal",
  data() {
    return {
      modalTitle: "",
      satisfiesValidation: false,
      isLoading: false,
      errorsForm: {},
      renderInput: 0,
      scacsOptions: null,
      accountIdValidator: false,
      cspId: null,
      extractedIds: null,
      editModeFlag: false,
      cspForm: {
        name: null,
        p44AccountGroup: null,
        parentAccountId: null,
        customerIds: null,
        scacs: null,
      },
    };
  },
  methods: {
    async AddCustomerId() {
      if (!this.editModeFlag) {
        this.editModeFlag = true;
        return;
      }
      this.extractedIds = this.cspForm.customerIds.map((item) => {
        if (item.includes("ID")) {
          const match = item.match(/\b\w{18}\b/);
          return match ? match[0] : item;
        } return item;
      });
      const customersNames = await this.$store.dispatch("accounts/getAccountNames", this.extractedIds);
      this.validateField('customerIds', { customerIds: this.extractedIds });
      this.cspForm.customerIds = customersNames.data
        ? customersNames.data.map((item) => `${item.name} (ID: ${item.id})`)
        : null;
    },
    async validateForm() {
      try {
        await validationCSPForm.validate(this.cspForm, { abortEarly: false });
        this.satisfiesValidation = true;
      } catch (errors) {
        errors.inner.forEach((element) => {
          this.errorsForm[element.path] = "error";
        });
        this.satisfiesValidation = false;
        this.renderInput += 1;
      }
    },
    validateField(key, obj) {
      validationCSPForm.validateAt(key, obj)
        .then(() => {
          if (key === "parentAccountId") {
            this.accountIdValidator = false;
          }
          this.errorsForm[key] = "input-valid";
          this.renderInput += 1;
        })
        .catch(() => {
          if (key === "parentAccountId") {
            this.accountIdValidator = true;
          }
          this.errorsForm[key] = "error";
          this.renderInput += 1;
        });
    },
    customerIdValidator(customerId) {
      const regex = new RegExp("^001[A-Za-z0-9]{15}$");
      return regex.test(customerId);
    },
    resetModal() {
      this.cspForm.name = null;
      this.cspForm.p44AccountGroup = null;
      this.cspForm.parentAccountId = null;
      this.cspForm.customerIds = null;
      this.cspForm.scacs = null;
      this.errorsForm = {};
      this.accountIdValidator = false;
      this.editModeFlag = false;
      this.renderInput += 1;
    },
    showModal(option) {
      this.resetModal();
      if (option) {
        this.editMode(option);
      } else {
        this.modalTitle = "New CSP";
      }
      this.getAllScacs();
      this.$refs["customer-specify-pricing"].show();
    },
    async getAllScacs() {
      this.scacsOptions = await this.$store.dispatch("carriers/getAllscacs");
    },
    async editMode(option) {
      // get by id is used to obtain full csp information.
      const cspById = (await this.$store.dispatch("csp/getCSPById", option.id)).data;
      this.editModeFlag = true;
      this.modalTitle = "Edit CSP";
      this.cspId = cspById.id;
      this.cspForm.name = cspById.name;
      this.cspForm.p44AccountGroup = cspById.p44_account_group;
      this.cspForm.scacs = cspById.scacs;
      this.cspForm.parentAccountId = cspById.parent_account_id
        ? cspById.parent_account_id.id
        : null;
      this.cspForm.customerIds = cspById.customer_ids
        ? cspById.customer_ids.map((item) => `${item.name} (ID: ${item.id})`)
        : null;
    },
    async handleRequest(type) {
      this.isLoading = true;
      this.cspForm.parentAccountId = this.cspForm.parentAccountId || null;
      const params = {
        id: this.cspId,
        body: {
          name: this.cspForm.name,
          p44_account_group: this.cspForm.p44AccountGroup,
          parent_account_id: this.cspForm.parentAccountId,
          customer_ids: this.extractedIds,
          scacs: this.cspForm.scacs,
        },
      };
      await this.validateForm();
      if (this.satisfiesValidation && type === "create") {
        await this.createNewCSP(params.body);
      } else if (this.satisfiesValidation && type === "edit") {
        await this.updateCSP(params);
      } else {
        warningMessage("Oops...", "Please complete the required fields.");
      }
      this.isLoading = false;
    },
    async createNewCSP(params) {
      const response = await this.$store.dispatch("csp/createCSP", params);
      if (response.status === 201) {
        successMessage(`The customer specify pricing has been successfully created`);
        this.$refs["customer-specify-pricing"].hide();
        this.$emit("getData");
      }
    },
    async updateCSP(params) {
      const response = await this.$store.dispatch("csp/updateCSP", params);
      if (response.status === 204) {
        successMessage(`The customer specify pricing has been updated.`);
        this.$refs["customer-specify-pricing"].hide();
        this.$emit("getData");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.customer-specify-pricing-modal {
  &__button {
    @include submit-button;
  }
  &__add-button {
    height: 35px;
  }
  &__row {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
  }
  &__column {
    display: flex;
    flex-direction: column;
    width: 48%;
    &__text {
      color: $color-input-text;
      margin-left: 0.2rem;
    }
    &__input {
      @include input;
    }
    &__descripcion {
      color:$color-input-error;
    font-size: 10px;
    padding-left: 8px;
    }
  }
}
.overlay-button {
  display: flex;
  margin: auto;
  justify-content: center;
  padding: 2rem 1rem 1rem;
}
:deep(.p-dropdown-label) {
  padding-top: 0.3rem;
}
.error {
  border-color: $color-input-error;
}
.input-valid {
  border:
   1px solid #00b900;
}

:deep .p-multiselect, .form-control {
  background-color: #fafafa;
  border-radius: 8px;
  height: 35px
}

</style>
