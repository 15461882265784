<template>
  <div class="data-table">
    <!-- SPINNER -->
    <div
      class="d-flex justify-content-center align-items-center spinner-container"
      v-if="isLoading"
    >
      <img src="@/assets/gif/spinner.gif" width="200" height="200" />
    </div>
    <DataTable
      v-else-if="!isLoading && !showEditor"
      :value="data"
      :paginator="false"
      class="p-datatable-customers"
      :rows="10"
      dataKey="id"
      :rowHover="true"
      responsiveLayout="scroll"
    >
      <template #header>
        <div class="data-table__header">
          <SplitButton v-if="isLTLUser" label="Add Rule" icon="pi pi-plus" :model="items"
          @click="displayCreate()">
          </SplitButton>
          <div v-else>
            <b-button
              v-if="rolPermissions.isAllowedCreate || type === 'CannedResponses' || type === 'CSP'"
              class="data-table__header--button"
              @click="displayCreate()"
              >{{ type == 'CannedResponses' ? `Add ${type}`: `New ${type}` }}</b-button
            >
          </div>
          <div class="data-table__container-filter">
            <Dropdown
              v-if="type === 'User' && loggedUser.role === 'department_admin'"
              class="data-table__container-filter--dropdown"
              v-model="departmentId"
              :options="userDepartments"
              optionLabel="name"
              optionValue="id"
              @change="getUsers()"
            />
            <b-button
              v-if="type === 'Accessorial'"
              class="data-table__container-filter--button-filter"
              @click="informationByType()"
            >
              <b-icon icon="funnel" aria-hidden="true"></b-icon>
              {{ getInfoByType === true ? `${type} By ${buttonName}` : `All ${type}` }}
            </b-button>
            <Dropdown
              v-if="type === 'Rule'"
              class="data-table__input"
              v-model="selectedOrder"
              :options="orderOptions"
              optionLabel="value"
              placeholder="Order by"
            />
            <div
              v-if="type !== 'Company' && type !== 'Department'"
              class="p-inputgroup data-table__container-filter-search"
            >
              <InputText
                :placeholder="type === 'User'
                ? 'Search by username'
                : `${type === 'CSP'} `
                ? 'Search by name, account group, customer id'
                : 'Search by name'"
                v-model="searchField"
                class="data-table__container-filter-search--input-text"
                v-on:keyup.enter="search()"
              />
              <Button
                icon="pi pi-times"
                class="p-button data-table__container-filter-search--button-clear"
                @click="clear()"
              />
              <Button
                icon="pi pi-search"
                class="data-table__container-filter-search--button"
                @click="search()"
              />
            </div>
          </div>
        </div>
      </template>
      <template #empty> No files found. </template>
      <Column
        v-if="type === 'CannedResponses' || type === 'Accessorial'"
        field="id"
        header="Id"
        :styles="{ 'min-width': '10rem' }"
      >
        <template #body="{ data }">
          <p>{{ data.id }}</p>
        </template>
      </Column>
      <Column
        field="name"
        :header="type === 'User' ? 'Username' : 'Name'"
        :styles="{ 'min-width': '10rem' }"
      >
        <template #body="{ data }">
          <p v-if="type === 'User'">{{ data.username }}</p>
          <p v-else-if="type === 'Accessorial'">{{ data.accessorial_name || data.name }}</p>
          <p v-else-if="type === 'Department'">{{ data.department_name }}</p>
          <p v-else>{{ data.name }}</p>
        </template>
      </Column>
      <Column
        v-if="type === 'Rule'"
        field="label"
        header="Label"
        :styles="{ 'min-width': '10rem' }"
      >
        <template #body="{ data }">
          <div class="data-table__chips-container">
            <el-collapse accordion>
              <el-collapse-item name="1">
                <template slot="title">
                  <span
                    v-for="(item, index) in data.label.slice(0, 3)"
                    :key="index"
                    class="data-table__chip"
                  >
                    {{ item }}
                  </span>
                </template>
                <div
                  v-for="(item, index) in data.label.slice(3)"
                  :key="index"
                  class="data-table__chip"
                >
                  {{ item }}
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
          <!-- <MultiSelect
            :options="data.label"
            v-model="selectedLabel[data.id]"
            placeholder="Select a label"
            display="chip"
          /> -->
        </template>
      </Column>
      <Column
        v-if="type === 'Department' || type === 'User'"
        field="name"
        header="Company"
        :styles="{ 'min-width': '10rem' }"
      >
        <template #body="{ data }">
          {{ data.company_name }}
          <p v-if="data.company_name === null">All</p>
        </template>
      </Column>
      <Column
        v-if="type === 'User'"
        field="name"
        header="Department"
        :styles="{ 'min-width': '10rem' }"
      >
        <template #body="{ data }">
          <p v-if="data.departments.length === 0">All</p>
          <p v-else :key="index" v-for="(dep, index) in data.departments">
            {{ dep.name }}
          </p>
        </template>
      </Column>
      <Column
        v-if="type === 'CannedResponses' || type === 'User'"
        field="status"
        header="Status"
        :styles="{ 'min-width': '10rem' }"
      >
        <template #body="{ data }">
          <p>{{ data.status }}</p>
        </template>
      </Column>
      <Column
        v-if="type === 'Accessorial'"
        field="type"
        header="Type"
        :styles="{ 'min-width': '10rem' }"
      >
        <template #body="{ data }">
          {{ data.accessorial_type || data.type }}
        </template>
      </Column>
      <Column
        v-if="type === 'Company' && loggedUser.role !== 'global_admin'"
        field="description"
        header="Description"
        :styles="{ 'min-width': '10rem' }"
      >
        <template #body="{ data }">
          {{ data.description }}
        </template>
      </Column>
      <Column
      v-if="type === 'CSP'"
      header="P44 Account Group"
      >
      <template #body="{ data }">
        {{ data.p44_account_group }}
      </template>
      </Column>
      <Column
      v-if="type === 'CSP'"
      header="Parent Account ID"
      >
      <template #body="{ data }">
        {{ data.parent_account_id?.name }}
      </template>
      </Column>
      <Column
      :styles="{ 'min-width': '10rem' }"
      v-if="type === 'CSP'"
      header="Customers Id's"
      >
      <template #body="{ data }">
        <div
          v-for="(customer, index) in data.customer_ids "
          :key="index"
        >
          {{ customer.name }}
        </div>
      </template>
      </Column>
      <Column
      v-if="!rolPermissions.noActions || type === 'Accessorial' || type === 'CannedResponses'"
      header="Actions">
        <template #body="{ data }">
          <div class="data-table__actions">
            <!-- <span v-if="type === 'Rule'"><i class="pi pi-history"></i></span> -->
            <span
              v-if="
                rolPermissions.isAllowedEdit ||
                (type === 'CannedResponses' &&
                (loggedUser.role === 'company_admin' || loggedUser.role === 'global_admin'
                || loggedUser.role === 'department_admin')) ||
                (type === 'Accessorial' &&
                  (loggedUser.role === 'global_admin' ||
                    (loggedUser.role !== 'global_admin' && getInfoByType)))
              "
              @click="editionModal(data)"
              ><i class="pi pi-pencil"></i
            ></span>
            <span
              v-if="
                rolPermissions.isAllowedDelete ||
                (type === 'CannedResponses' &&
                (loggedUser.role === 'company_admin' || loggedUser.role === 'global_admin'
                || loggedUser.role === 'department_admin')) ||
                (type === 'Accessorial' &&
                  (loggedUser.role === 'global_admin' ||
                    (loggedUser.role !== 'global_admin' && !getInfoByType)))
              "
              @click="deleteRow(data)"
              ><i class="pi pi-trash"></i
            ></span>
            <InputSwitch
              v-if="type == 'Profile'"
              v-model="data.checked"
              @click="updateProfileStatus(data)"
            />
          </div>
        </template>
      </Column>
    </DataTable>
    <b-pagination
      v-if="!isLoading && !showEditor"
      v-model="currentPage"
      pills
      :total-rows="totalItemsCount"
      :per-page="perPage"
      class="my-0"
      @change="paginate"
      aria-controls="data"
    >
    </b-pagination>
    <div v-if="type === 'CannedResponses' && showEditor" class="canned-responses__content__editor">
      <Button
        icon="pi pi-arrow-left"
        class="p-button-rounded p-button-outlined canned-responses__content__button"
        @click="backToCards()"
      />
      <ResponsesEditor
        :rowDataCanned="currentRowDataCanned"
        :currentCompanyDepartment="currentCompanyDepartment"
        :typeCrud="typeCrud"
        @updateCannedResponsesData="updateCannedResponsesData"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { successMessage, deleteMessage } from "@/utils/alertMessages";
import ResponsesEditor from "@/views/CannedResponses/Components/Editor.vue";
import { userInformation, rolPermissions } from "../mixins/itemsTable/infoUser";

export default {
  name: "Table",
  props: ["type", "firstPage"],
  components: {
    ResponsesEditor,
  },
  data() {
    return {
      isLoading: false,
      searchField: "",
      data: [],
      loggedUser: null,
      rolPermissions: {},
      confirmationDeleteMessage: "I'm sure I want to delete this",
      showEditor: false,
      typeCrud: null,
      currentCompanyDepartment: null,
      currentRowDataCanned: null,
      getInfoByType: true,
      buttonName: null,
      userDepartments: [],
      departmentId: null,
      totalItemsCount: 0,
      currentPage: 1,
      perPage: 15,
      selectedOrder: { label: "name", value: "Order by name" },
      orderOptions: [
        { label: "name", value: "Order by name" },
        { label: "rule_weight", value: "Order by rule weight" },
        { label: "profile_id", value: "Order by name profile id" },
      ],
      selectedLabel: [],
      items: [
        {
          label: "LTL Rule",
          icon: "pi pi-plus",
          command: () => { this.$router.push({ name: "CreateLTLRule" }); },
        },
      ],
      isLTLUser: false,
    };
  },
  computed: {
    ...mapGetters("cannedResponses", ["showAllCannedResponses"]),
    ...mapGetters("user", ["showCurrentCompanyDepartment"]),
  },
  watch: {
    showAllCannedResponses() {
      if (this.showAllCannedResponses) {
        this.data = this.showAllCannedResponses?.data;
        this.totalItemsCount = this.showAllCannedResponses?.count;
      }
    },
    async showCurrentCompanyDepartment() {
      if (this.showCurrentCompanyDepartment) {
        this.showSplitButton();
        this.searchField = "";
        this.currentCompanyDepartment = this.showCurrentCompanyDepartment;
        this.getButtonName();
        this.backToCards();
        await this.getInfoProfile();
        if (this.type === "Accessorial") {
          await this.updateInfoByType();
        } else if (this.type === "Rule") {
          await this.getAllRules();
        } else if (this.type === "CannedResponses") {
          await this.getAllCannedResponses();
        }
      }
    },
    firstPage() {
      this.currentPage = 1;
    },
    selectedOrder() {
      this.getAllRules();
    },
  },
  async created() {
    this.showSplitButton();
    await this.getData();
  },
  methods: {
    showSplitButton() {
      this.isLTLUser = this.showCurrentCompanyDepartment.chosenDepartment.name === 'LTL' && this.type === 'Rule';
    },
    async paginate(page) {
      this.currentPage = page;
      let params;
      if (this.searchField) {
        params = {
          name: this.searchField,
        };
      }
      if (this.type === "User") {
        if (this.loggedUser.role === "department_admin") {
          this.userDepartments = this.loggedUser.company_info[0].departments;
          this.departmentId = this.loggedUser.company_info[0].departments[0].id;
        }
        await this.getUsers(params);
        this.getUserPermissions();
      } else if (this.type === "Accessorial") {
        this.updateInfoByType(params);
      } else if (this.type === "Department") {
        this.getUserPermissions();
        this.getdepartments();
      } else if (this.type === "Company") {
        this.getCompanies();
      } else if (this.type === "Profile") {
        this.getprofiles(params);
      } else if (this.type === "Rule") {
        await this.getInfoProfile();
        await this.getAllRules(params);
      } else if (this.type === "CannedResponses") {
        await this.getAllCannedResponses(params);
        this.getInfoProfile();
      } else if (this.type === "CSP") {
        await this.getAllCSP(params);
      }
      window.scrollTo(0, 0);
    },
    async getData() {
      this.isLoading = true;
      this.currentCompanyDepartment = this.showCurrentCompanyDepartment;
      this.loggedUser = JSON.parse(localStorage.getItem("user-info"));
      if (this.type === "CannedResponses") {
        await this.getInfoProfile();
        await this.getAllCannedResponses();
        this.data = this.showAllCannedResponses?.data;
        this.totalItemsCount = this.showAllCannedResponses?.count;
      }
      this.getUserPermissions();
      this.getButtonName();
      if (this.type === "User") {
        if (this.loggedUser.role === "department_admin") {
          this.userDepartments = this.loggedUser.company_info[0].departments;
          this.departmentId = this.loggedUser.company_info[0].departments[0].id;
        }
        await this.getUsers();
        this.getUserPermissions();
      } else if (this.type === "Accessorial") {
        this.getInfoByType = true;
        await this.getInfoProfile();
        await this.getAllAccessorials();
      } else if (this.type === "Department") {
        this.getUserPermissions();
      } else if (this.type === "Rule") {
        await this.getInfoProfile();
        await this.getAllRules();
      } else if (this.type === "CSP") {
        await this.getAllCSP();
      }
      this.isLoading = false;
    },
    async editionModal(rowData) {
      if (this.type === "CannedResponses") {
        this.isLoading = true;
        await this.getCannedResponseById(rowData.id);
        this.isLoading = false;
        this.showEditor = true;
        this.typeCrud = "edit";
      } else if (this.type === "Accessorial") {
        const dataModal = {
          rowInfo: rowData,
          modalType: "Edit",
        };
        this.$emit("openModal", dataModal);
      } else if (this.type === "Rule") {
        this.$emit("editRule", rowData);
      } else {
        this.$emit("openModal", rowData);
      }
    },
    async getUsers(params) {
      this.isLoading = true;
      this.searchField = params?.name ? this.searchField : "";
      const user = this.getUserInfo(this.loggedUser);
      user.limit = 15;
      user.skip = (this.currentPage - 1) * 15;
      if (params) {
        user.username = params.name;
      }
      if (this.loggedUser.role === "department_admin") {
        user.department_id = this.departmentId;
      }
      const info = await this.$store.dispatch("user/getUsersCompleteInfo", user);
      this.totalItemsCount = info.count;
      this.data = info.data;
      this.isLoading = false;
    },
    async getCompanies() {
      this.isLoading = true;
      const infoCompanies = {};
      infoCompanies.limit = 15;
      infoCompanies.skip = (this.currentPage - 1) * 15;
      const info = await this.$store.dispatch("company/getAllCompanies", infoCompanies);
      this.data = info.data;
      this.totalItemsCount = info.count;
      this.isLoading = false;
    },
    async getdepartments() {
      this.isLoading = true;
      const params = {};
      if (this.loggedUser.role === "global_admin") {
        params.company_id = -2;
        params.department_id = -2;
      } else if (this.loggedUser.role === "company_admin") {
        params.company_id = this.loggedUser.company_id;
        params.department_id = -2;
      } else if (this.loggedUser.role === "department_admin") {
        params.company_id = this.loggedUser.company_id;
        params.department_id = this.loggedUser.company_info[0].departments[0].id;
      }
      params.limit = 15;
      params.skip = (this.currentPage - 1) * 15;
      const info = await this.$store.dispatch("company/getDepartmentsPerCompany", params);
      this.data = info.data;
      this.totalItemsCount = info.count;
      this.isLoading = false;
    },
    async getprofiles(params) {
      this.searchField = params?.name ? this.searchField : "";
      this.isLoading = true;
      const payload = { ...params };
      payload.limit = 15;
      payload.skip = (this.currentPage - 1) * 15;
      const info = await this.$store.dispatch("profile/getAllProfiles", payload);
      this.data = info.data;
      this.totalItemsCount = info.count;
      this.data.forEach((profile, index) => {
        if (profile.status === "active") {
          this.data[index].checked = true;
        } else {
          this.data[index].checked = false;
        }
      });
      this.isLoading = false;
    },
    getUserInfo() {
      return userInformation(this.loggedUser);
    },
    getUserPermissions() {
      this.rolPermissions = rolPermissions(this.loggedUser, this.type);
    },
    async getAllAccessorials(payload) {
      this.searchField = payload?.name ? this.searchField : "";
      this.isLoading = true;
      const params = {
        limit: 15,
        skip: (this.currentPage - 1) * 15,
      };
      if (payload) {
        params.name = payload.name;
      }
      const response = await this.$store.dispatch("accessorial/getAllAccessorials", params);
      this.data = response.data;
      this.totalItemsCount = response.count;
      this.isLoading = false;
    },
    async getAccesorialsByCurrentInfo(data) {
      this.isLoading = true;
      this.searchField = data?.name ? this.searchField : "";
      const payload = {
        company_id: this.currentCompanyDepartment.chosenCompany.id,
        profile_id: this.profileACtive.data[0].profile_id,
        department_id: this.currentCompanyDepartment.chosenDepartment.id,
        limit: 15,
        skip: (this.currentPage - 1) * 15,
      };
      if (data) {
        payload.accessorial_name = data.name;
      }
      const response = await this.$store.dispatch("accessorial/getAccessorialsByProfile", payload);
      this.data = response.data;
      this.totalItemsCount = response.count;
      this.isLoading = false;
    },
    async getInfoProfile() {
      this.isLoading = true;
      const payload = {};
      payload.company_id = this.currentCompanyDepartment.chosenCompany.id;
      payload.department_id = this.currentCompanyDepartment.chosenDepartment.id;
      const response = await this.$store.dispatch("company/getProfileCompanysDepartment", payload);
      this.isLoading = false;
      this.profileACtive = response.status === 200 ? response.data : null;
    },
    async deleteRow(data) {
      const validateRole = this.type === "Accessorial" && !this.getInfoByType;
      const msg = validateRole
        ? `Are you sure you want to remove the ${this.type} from the ${this.buttonName} profile?`
        : `Are you sure you want to remove ${this.type} from the system?`;
      const warningResponse = await deleteMessage(msg, "You won't be able to revert this!");
      if (warningResponse.isConfirmed) {
        if (this.type === "User") {
          this.deleteUser(data);
        } else if (this.type === "Company") {
          this.deleteCompany(data.id);
        } else if (this.type === "Department") {
          this.deleteDepartment(data.department_id);
        } else if (this.type === "Profile") {
          this.deleteProfile(data.id);
        } else if (this.type === "Accessorial") {
          this.deleteAccessorial(data.accessorial_id || data.id);
        } else if (this.type === "CannedResponses") {
          this.deleteCannedResponses(data);
        } else if (this.type === "Rule") {
          this.deleteRule(data);
        } else if (this.type === "CSP") {
          this.deleteCSP(data);
        }
      }
    },
    async deleteAccessorial(id) {
      let response;
      if (this.loggedUser.role === "global_admin" && this.getInfoByType) {
        response = await this.$store.dispatch("accessorial/deleteAccessorial", id);
      } else {
        const params = {
          accessorial_id: id,
          profile_id: this.profileACtive.data[0].profile_id,
          company_id: this.currentCompanyDepartment.chosenCompany.id,
          department_id: this.currentCompanyDepartment.chosenDepartment.id,
        };
        response = await this.$store.dispatch("accessorial/deleteAccessorialByProfile", params);
      }
      if (response.status === 204 || response.status === 201) {
        successMessage("Accessorial has been deleted");
        this.updateCurrentPage();
        this.updateInfoByType();
      }
    },
    async deleteUser(user) {
      const params = this.getUserInfo(this.loggedUser);
      params.user_id = user.id;
      params.confirmation_message = this.confirmationDeleteMessage;
      if (this.loggedUser.role === "department_admin") {
        params.department_id = user.departments[0].id;
      }
      const response = await this.$store.dispatch("user/deleteUser", params);
      if (response.status === 200 || response.status === 204) {
        successMessage(`The user has been deleted.`);
        this.updateCurrentPage();
        this.getUsers();
      }
    },
    async deleteCompany(companyId) {
      const params = {
        company_id: companyId,
        confirmation_message: this.confirmationDeleteMessage,
      };
      const response = await this.$store.dispatch("company/deleteCompany", params);
      if (response.status === 200 || response.status === 204) {
        successMessage(`The company has been deleted.`);
        this.updateCurrentPage();
        this.getCompanies();
      }
    },
    async deleteDepartment(departmentId) {
      const params = {
        department_id: departmentId,
        confirmation_message: this.confirmationDeleteMessage,
      };
      const response = await this.$store.dispatch("department/deleteDepartment", params);
      if (response.status === 200 || response.status === 204) {
        successMessage(`The department has been deleted.`);
        this.updateCurrentPage();
        this.getdepartments();
      }
    },
    async deleteProfile(profileId) {
      const params = {
        profile_id: profileId,
        confirmation_message: this.confirmationDeleteMessage,
      };
      const response = await this.$store.dispatch("profile/deleteProfile", params);
      if (response.status === 200 || response.status === 204) {
        successMessage(`The profile has been deleted.`);
        this.updateCurrentPage();
        this.getprofiles();
      }
    },
    async deleteCannedResponses(data) {
      const info = {};
      info.company_id = this.currentCompanyDepartment.chosenCompany.id;
      info.department_id = this.currentCompanyDepartment.chosenDepartment.id;
      info.canned_response_id = data.id;
      info.confirmation_message = "I'm sure I want to delete this";
      const response = await this.$store.dispatch("cannedResponses/deleteCannedResponse", info);
      if (response.status === 204) {
        successMessage("Canned responses has been deleted");
        this.updateCurrentPage();
        await this.getAllCannedResponses();
      }
    },
    async updateProfileStatus(profile) {
      let newStatus = "";
      if (profile.status === "active") {
        newStatus = "inactive";
      } else {
        newStatus = "active";
      }
      const params = {
        profile_id: profile.id,
        data: {
          status: newStatus,
        },
      };
      const response = await this.$store.dispatch("profile/updateProfile", params);
      if (response.status === 200 || response.status === 204) {
        successMessage(`The profile has been updated.`);
        this.getprofiles();
      }
    },
    backToCards() {
      this.showEditor = false;
    },
    displayCreate() {
      if (this.type === "CannedResponses") {
        this.showEditor = true;
        this.typeCrud = "create";
      } else if (this.type === "Accessorial") {
        const dataModal = {
          modalType: "Create",
        };
        this.$emit("openModal", dataModal);
      } else {
        this.$emit("openModal");
      }
    },
    informationByType() {
      this.getInfoByType = !this.getInfoByType;
      this.updateInfoByType();
    },
    async updateInfoByType(params) {
      if (this.type === "Accessorial") {
        if (this.getInfoByType) {
          await this.getAllAccessorials(params);
        } else {
          await this.getAccesorialsByCurrentInfo(params);
        }
      }
    },
    getButtonName() {
      this.buttonName = this.currentCompanyDepartment.chosenDepartment.id === -1
        ? this.currentCompanyDepartment.chosenCompany.name
        : this.currentCompanyDepartment.chosenDepartment.name;
    },
    async search() {
      if (this.searchField) {
        this.currentPage = 1;
        const params = {
          name: this.searchField,
        };
        if (this.type === "User") {
          this.getUsers(params);
        } else if (this.type === "Profile") {
          this.getprofiles(params);
        } else if (this.type === "Accessorial") {
          this.updateInfoByType(params);
        } else if (this.type === "CannedResponses") {
          await this.getAllCannedResponses(params);
        } else if (this.type === "Rule") {
          await this.getAllRules(params);
        } else if (this.type === "CSP") {
          this.getAllCSP(params);
        }
      }
    },
    async clear() {
      this.searchField = "";
      this.currentPage = 1;
      if (this.type === "User") {
        await this.getUsers();
      } else if (this.type === "Profile") {
        await this.getprofiles();
      } else if (this.type === "Accessorial") {
        this.updateInfoByType();
      } else if (this.type === "CannedResponses") {
        await this.getAllCannedResponses();
      } else if (this.type === "Rule") {
        await this.getAllRules();
      } else if (this.type === "CSP") {
        await this.getAllCSP();
      }
    },
    // Rules Methods
    async getAllRules(data) {
      this.isLoading = true;
      this.searchField = data?.name ? this.searchField : "";
      const params = {};
      params.profile_id = this.profileACtive?.data[0].profile_id;
      params.company_id = this.currentCompanyDepartment.chosenCompany.id;
      params.department_id = this.currentCompanyDepartment.chosenDepartment.id;
      params.order_by = this.selectedOrder.label;
      params.limit = 15;
      params.skip = (this.currentPage - 1) * 15;
      if (data) {
        params.name = data.name;
      }
      const rulesData = await this.$store.dispatch("rules/getAllRules", params);
      this.data = rulesData.data;
      this.totalItemsCount = rulesData.count;
      this.isLoading = false;
    },
    async deleteRule(data) {
      const params = {};
      params.company_id = this.currentCompanyDepartment.chosenCompany.id;
      params.department_id = this.currentCompanyDepartment.chosenDepartment.id;
      params.profile_id = this.profileACtive?.data[0].profile_id;
      params.rule_id = data.id;
      params.confirmation_message = "I'm sure I want to delete this";
      const response = await this.$store.dispatch("rules/deleteRule", params);
      if (response?.status === 204) {
        successMessage("Rule has been deleted");
        this.getAllRules();
      }
    },
    // CannedResponse Methods
    async getAllCannedResponses(payload) {
      this.isLoading = true;
      this.searchField = payload?.name ? this.searchField : "";
      const params = {
        limit: 15,
        skip: (this.currentPage - 1) * 15,
      };
      if (payload) {
        params.name = payload.name;
      }
      if (this.loggedUser.role === "company_admin") {
        params.company_id = this.currentCompanyDepartment.chosenCompany.id;
        params.department_id = -2;
      } else {
        params.company_id = this.currentCompanyDepartment.chosenCompany.id;
        params.department_id = this.currentCompanyDepartment.chosenDepartment.id;
      }
      await this.$store.dispatch("cannedResponses/getAllCannedResponses", params);
      this.isLoading = false;
    },
    async updateCannedResponsesData() {
      this.showEditor = false;
      await this.getAllCannedResponses();
    },
    async getCannedResponseById(id) {
      const params = {};
      params.company_id = this.currentCompanyDepartment.chosenCompany.id;
      params.department_id = this.currentCompanyDepartment.chosenDepartment.id;
      params.canned_response_id = id;
      const response = await this.$store.dispatch("cannedResponses/getCannedResponseById", params);
      this.currentRowDataCanned = response.data;
    },
    async getAllCSP(payload) {
      this.searchField = payload?.name ? this.searchField : "";
      this.isLoading = true;
      const params = {
        limit: 15,
        skip: (this.currentPage - 1) * 15,
      };
      if (payload) {
        params.q = payload.name;
      }
      const response = await this.$store.dispatch("csp/getAllACSP", params);
      this.data = response.data;
      this.totalItemsCount = response.count;
      this.isLoading = false;
    },
    async deleteCSP(data) {
      const response = await this.$store.dispatch("csp/deleteCSP", data.id);
      if (response.status === 204) {
        this.updateCurrentPage();
        await this.getAllCSP();
        successMessage("CSP has been deleted");
      }
    },
    updateCurrentPage() {
      if ((this.data.length - 1) % 15 === 0 && this.data.length !== 1) {
        this.currentPage -= 1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.data-table {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &__header {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    &--button {
      @include submit-button;
    }
  }
  &__input {
    border-radius: 4px;
    margin-right: 0.5rem;
  }
  &__container-filter {
    display: flex;
    align-items: center;
    width: 55%;
    flex-direction: row;
    justify-content: flex-end;
    &--dropdown {
      height: 30px;
      display: flex;
      align-items: center;
      margin: 0 0.5rem;
    }
    &--button-filter {
      @include submit-button;
      width: 45%;
      margin-right: 0.5rem;
    }
  }
  &__container-filter-search {
    width: 80%;
    &--input-text {
      border-radius: 8px 0 0 8px;
      border: none;
    }
    &--button {
      @include submit-button;
      border-color: $color-submit-button;
      border-radius: 0px 8px 8px 0;
    }
    &--button-clear {
      background-color: $color-white;
      border-color: $color-white;
      color: gray;
      border: 0;
      &:hover, &:active {
        background-color: $color-white !important;
        border-color: $color-white !important;
        color: gray !important;
      }
    }
  }
  // &__chips-container {
  // background-color: red;
  // height: 100px;
  // }
  &__chip {
    display: inline-block;
    width: auto;
    height: auto;
    padding: 5px 15px;
    background-color: #f1f1f1;
    border-radius: 25px;
    margin: 0.2rem;
    font-size: 12px;
  }
  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      cursor: pointer;
    }
  }
}
:deep([role="button"]) {
  background-color: transparent;
  line-height: auto;
}
:deep(.el-collapse-item__wrap) {
  background-color: transparent;
}
:deep(.el-collapse-item__header) {
  line-height: 100%;
}
.pi-history {
  color: #000000;
  padding-right: 0.5rem;
}
.pi-pencil {
  color: #17a2b8;
  padding-right: 0.5rem;
}
.pi-trash {
  color: #c60707;
  padding-right: 0.5rem;
}
:deep(.p-datatable-header) {
  background-color: $color-background-dark !important;
}
:deep(.p-datatable) {
  width: 96%;
}
:deep(.p-column-header-content) {
  justify-content: start;
}
:deep(.pagination) {
  padding-bottom: 0.625rem;
}
.canned-responses__content {
  &__editor {
    display: flex;
    justify-content: space-between;
    width: 85%;
  }
  &__button {
    @include button-outlined;
    margin-top: 1rem;
  }
}
// :deep(.p-multiselect-chip) {
//   width: 400px;
// }

@media (max-width: 912px) and (min-width: 761px) {
  .data-table {
    &__container-filter {
      width: 70%;
    }
  }
}

@media (max-width: 760px) and (min-width: 661px) {
  .data-table {
    &__container-filter {
      margin: 10px;
      width: 70%;
      flex-direction: column;
      align-items: flex-end;
    }
    &__container-filter--button-filter {
      width: 50%;
      margin-bottom: 0.8rem;
      margin-right: 0;
    }
    &__input {
      margin-bottom: 0.5rem;
    }
    &__container-filter-search {
      width: 100%;
    }
    &__header--button {
      margin: 10px;
    }
  }
}

@media (max-width: 660px) {
  .data-table {
    &__header {
      flex-direction: column;
      align-items: flex-start;
      &--button {
        margin: 0 0 10px 10px;
      }
    }
    &__container-filter {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 96%;
      margin: 0 0 0 10px;
    }
    &__container-filter--button-filter {
      width: 13.13rem;
      margin-bottom: 0.8rem;
      margin-right: 0;
    }
    &__input {
      margin-bottom: 0.5rem;
    }
    &__container-filter-search {
      width: 100%;
    }
  }
  .canned-responses__content__editor {
    flex-direction: column;
  }
}
:deep(.p-splitbutton-defaultbutton) {
  @include submit-button;
}
:deep(.p-splitbutton-menubutton) {
  @include submit-button;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
</style>
