<template>
    <div class="customer-specify-pricing-view">
      <div class="customer-specify-pricing-view__sidebar">
        <Sidebar />
      </div>
      <!-- SPINNER -->
      <div
        class="d-flex justify-content-center align-items-center spinner-container"
        v-if="isLoading"
      >
        <img src="@/assets/gif/spinner.gif" width="200" height="200" />
      </div>
      <!-- CSP VIEW -->
      <div class="customer-specify-pricing-view__content">
        <h1 class="customer-specify-pricing-view__content__tittle">Customer Specific Pricing</h1>
        <hr class="customer-specify-pricing-view__content__separator" />
        <div
        class="d-flex justify-content-center align-items-center spinner-container"
        v-if="isLoading"
      >
        <img src="@/assets/gif/spinner.gif" width="200" height="200" />
      </div>
      <Table
        v-if="!isLoading"
        @openModal="openModal"
        :type="type"
        ref="CSPTable"
        class="mt-5"
      />
      <!-- MODAL -->
      <CustomerSpecifyPricingModal
        ref="CustomerSpecifyPricingModal"
        @getData = "getAllCSP"
      />
      </div>
    </div>
  </template>

<script>
import Sidebar from "@/components/Sidebar.vue";
import Table from "@/components/Table.vue";
import CustomerSpecifyPricingModal from "./Components/CSPModal.vue";

export default {
  name: "CSP",
  components: {
    Sidebar,
    Table,
    CustomerSpecifyPricingModal,
  },
  data() {
    return {
      isLoading: false,
      type: 'CSP',
    };
  },
  created() {

  },
  methods: {
    openModal(option) {
      this.$refs.CustomerSpecifyPricingModal.showModal(option);
    },
    getAllCSP() {
      this.$refs.CSPTable.getData();
    },
  },
};
</script>

  <style lang="scss" scoped>
  .customer-specify-pricing-view {
    display: flex;
    &__sidebar {
      width: 9%;
    }
    &__content {
      width: 91%;
      margin-top: 2rem;
      &__tittle {
        font-weight: bold;
      }
      &__separator {
        width: 95%;
        margin: auto;
      }
    }
  }
  :deep(.p-tabview-nav-container) {
    width: 95%;
    margin-left: 2rem;
  }
  :deep(.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link) {
    border-color: #283747;
    color: #283747;
  }
  :deep(.p-tabview .p-tabview-nav li .p-tabview-nav-link) {
    text-decoration: none;
  }
  :deep(.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus) {
    box-shadow: none;
  }
  @media (max-width: 900px) {
    .customer-specify-pricing-view {
      &__sidebar {
        width: 0%;
      }
      &__content {
        width: 98%;
        margin-top: 7rem;
      }
    }
  }
  </style>
